/*
 * @Author: 庄志莹
 * @Date: 2021-04-22 16:04:38
 * @LastEditTime: 2021-04-24 19:38:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \demo\src\axios\http.js
 */
import axios from 'axios'
import Vue from 'vue'
axios.defaults.timeout = 5000
axios.defaults.baseURL = process.env.VUE_APP_BASEURL

axios.interceptors.request.use(
  (config) => {
    config.data = JSON.stringify(config.data)
    config.headers = {
      'Content-Type': 'application/json',
      Authorization: "Bearer "+Vue.ls.get('token'),
    }
    //开始显示Loading
    // store.state.loadding = true; //在请求发出之前进行一些操作
    return config
  },
  (err) => {
    //开始显示LoadingBar
    return Promise.reject(err)
  }
)

function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(
      (response) => {
        // store.state.loadding = false;//在这里对返回的数据进行处理
        resolve(response.data)
        if(response.data.code===-2001){
          window.open("https://cloud.jsxniu.com/login","_self");
        }
      },
      (err) => {
        reject(err)
      }
    )
  })
}


function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: params }).then((response) => {
      // store.state.loadding = false;//在这里对返回的数据进行处理
      resolve(response.data)
      if(response.data.code===-2001){
        window.open("https://cloud.jsxniu.com/login","_self");
      }
    })
      .catch((err) => {
        reject(err)
      })
  })
}


function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, data, {
        headers: 'Content-Type: application/json;charset=UTF-8',
      })
      .then(
        (response) => {
          // store.state.loadding = false;//在这里对返回的数据进行处理
          resolve(response.data)
          if(response.data.code===-2001){
            window.open("https://cloud.jsxniu.com/login","_self");
          }
        },
        (err) => {
          reject(err)
        }
      )
  })
}


function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data)
        if(response.data.code===-2001){
          window.open("https://cloud.jsxniu.com/login","_self");
        }
      },
      (err) => {
        reject(err)
      }
    )
  })
}


function remove(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { params: params })
      .then((response) => {
        resolve(response.data)
        if(response.data.code===-2001){
          window.open("https://cloud.jsxniu.com/login","_self");
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}



export default {
  get: get,
  post: post,
  put: put,
  patch: patch,
  remove: remove,
}
