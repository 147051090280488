/*
 * @Author: your name
 * @Date: 2021-04-22 16:16:42
 * @LastEditTime: 2021-07-26 14:56:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \demo\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import constantRouter from './constantRouter'
Vue.use(VueRouter)


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_BASEURL,
  routes: constantRouter,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})


//路由守卫，路由跳转之前的钩子
router.beforeEach((to, from, next) => {
  NProgress.start()
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
