/*
 * @Author: 庄志莹
 * @Date: 2021-04-22 16:08:29
 * @LastEditTime: 2021-08-06 15:29:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \demo\src\axios\api.js
 */
import http from './http'

export const api = {
  //精品资源列表
  getBoutiqueList(params) {
    return http.get('/resource/xnFile/boutique/list', params)
  },
  //轮播图图片
  getBanner(params) {
    return http.get('/resource/xn-banner', params)
  },
  //根据bannerId得到热点资讯
  getHotNews(bannerId) {
    return http.get('/resource/xn-banner/' + bannerId)
  },
  //导航列表
  getNavList(params) {
    return http.get('/resource/category', params)
  },
  //二级详情列表
  getDetailList(params) {
    return http.get('/resource/xnFile/list', params)
  },
  //三级详情
  getPreview(id, pageSize) {
    return http.get('/resource/xnFile/detail/' + id + "/" + pageSize)
  },
  //三级详情收藏状态
  getCollectionStatus(id) {
    return http.get('/resource/collection/checkCollect/' + id )
  },
  //三级详情改变收藏状态
  changeCollectionStatus(id) {
    return http.get('/resource/collection/collect/' + id )
  },
  //三级详情改变收藏状态时获取收藏次数
  getCollectionCount(id) {
    return http.get('/resource/xnFile/collectionCount/' + id )
  },
  //推荐精品资源列表
  getRecommendList(id, pageSize) {
    return http.get('/resource/xnFile/boutiqueById/' + id + "/" + pageSize)
  },
  //首页ppt列表
  getPptList(id, pageSize, imgSize) {
    return http.get('/resource/xnFile/list/' + id + "/" + pageSize + "/" + imgSize)
  },
  //三级详情下载链接
  getdownloadUrl(params) {
    return http.post('/resource/xnFile/download', params)
  },
  //个人中心 得到收藏下载上传数量
  getRecordAll(params) {
    return http.get('/resource/collection/recordAll', params)
  },
  //个人中心 收藏列表
  getCollectionList(params) {
    return http.get('/resource/collection/list', params)
  },
  //个人中心 下载列表
  getDownloadList(params) {
    return http.get('/resource/download/list', params)
  },
  //我的上传 获取sts
  getSts(params){
    return http.get('/resource/upload/getStsInfo', params)
  },
  //我的上传 上传文件
  uploadFile(params){
    return http.post('/resource/upload', params)
  },
  //我的上传 撤销
  backFile(id){
    return http.remove('/resource/upload/'+id)
  },
  //我的上传 上传列表
  getUploadList(params){
    return http.get('/resource/upload/list', params)
  }
}