<!--
 * @Author: 庄志莹
 * @Date: 2021-04-22 15:56:57
 * @LastEditTime: 2021-07-23 18:45:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \demo\src\App.vue
-->
<template>
  <div id="app" ref="app">
    <a-config-provider :locale="locale">
      <router-view v-if="isRouterAlive" />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      locale: zhCN,
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
#nprogress .bar {
  background: #05ae44 !important;
}
::-webkit-scrollbar {
  width: 0;
}
</style>
