/*
 * @Author: your name
 * @Date: 2021-04-22 16:18:20
 * @LastEditTime: 2021-08-12 14:42:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \demo\src\router\constantRouter.js
 */
const constantRouter = [
  {
    path: '/',
    redirect: '/index',
  },
  //首页
  {
    path: '/index',
    name: 'index',
    meta: {
      title: '首页',
    },
    component: () => import('@/views/index'),
  },
  //登录
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login/index'),
  },
  //扫码登录
  {
    path: '/scan',
    name: 'scan',
    meta: {
      title: '扫码登录',
    },
    component: () => import('@/views/scan/index'),
  },
  //忘记密码
  {
    path: '/forget',
    name: 'forget',
    meta: {
      title: '忘记密码',
    },
    component: () => import('@/views/forget/index'),
  },
  //个人中心
  {
    path: '/user',
    name: 'user',
    meta: {
      title: '个人中心',
    },
    component: () => import('@/views/user/index'),
    redirect: '/user-home',
    children: [
      //首页
      {
        path: '/user-home',
        name: 'user-home',
        component: () => import('@/views/user/home.vue'),
      },
      //个人信息
      {
        path: '/user-information',
        name: 'user-information',
        component: () => import('@/views/user/information.vue'),
      },
      //账号绑定
      {
        path: '/user-account',
        name: 'user-account',
        component: () => import('@/views/user/account.vue'),
      },
      //修改密码
      {
        path: '/user-repair-password',
        name: 'user-repair-password',
        component: () => import('@/views/user/repairPassword.vue'),
      },
      //我的下载
      {
        path: '/user-download',
        name: 'user-download',
        component: () => import('@/views/user/download.vue'),
      },
      //我的收藏
      {
        path: '/user-collection',
        name: 'user-collection',
        component: () => import('@/views/user/collection.vue'),
      },
      //我的上传
      {
        path: '/user-upload',
        name: 'user-upload',
        component: () => import('@/views/user/upload.vue'),
      },
    ]
  },
  //热点资讯详情
  {
    path: '/hot-news',
    name: 'hot-news',
    meta: {
      title: '热点资讯',
    },
    component: () => import('@/views/banner/hotNews'),
  },
  //二级详情
  {
    path: '/secondary-details',
    name: 'secondary-details',
    meta: {
      title: '二级详情',
    },
    component: () => import('@/views/secondary-details/index'),
  },
  //预览页
  {
    path: '/preview',
    name: 'preview',
    meta: {
      title: '预览详情',
    },
    component: () => import('@/views/preview/index'),
  },
]

export default constantRouter
