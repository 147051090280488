/*
 * @Author: 庄志莹
 * @Date: 2021-04-22 15:56:57
 * @LastEditTime: 2021-08-16 14:33:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \demo\src\main.js
 */
import Vue from 'vue'

//less
import less from 'less'
Vue.use(less)


//ant 框架
import Antd from 'ant-design-vue'
//ant的样式引入
import 'ant-design-vue/dist/antd.less'
Vue.use(Antd)

//自定义的样式
import '../src/assets/css/common.css'



//全局使用ls(缓存)
import VueStorage from 'vue-ls'
Vue.use(VueStorage, {
  namespace: 'pro__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
})

//使用rem自适应
import './utils/rem.js'

import App from './App.vue'

Vue.config.productionTip = false


import router from './router'
import store from './store'
import { api } from './axios/api'
Vue.prototype.$api = api


new Vue({
  router,
  store,
  mounted: function () {
    // Vue.ls.set('foo', 'boo')
    // // 设置有效期
    // Vue.ls.set('foo', 'boo', 60 * 60 * 1000 * 24) //有效24小时
  },
  render: h => h(App),
}).$mount('#app')
